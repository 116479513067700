import * as React from "react"
import { withPreviewResolver, usePrismicPreview } from "gatsby-source-prismic"
import PageTemplate from "../templates/page"
import ProjectTemplate from "../templates/project"
import { navigate } from "gatsby"
import get from "lodash.get"
export default function PreviewPage() {
  const { isLoading, isPreview, previewData, path } = usePrismicPreview({
    repositoryName: "vikrams-personal-website",
    linkResolver: ({ node, key, value }) => (doc) => {
      // Pretty URLs for known types
      if (doc.type === "project") return "/project/" + doc.uid
      if (doc.type === "page") {
        if (doc.uid == "homepage") {
          return "/"
        } else {
          return "/" + doc.uid
        }
      }
      // Fallback for other types, in case new custom types get created
      return doc.uid
    },
  })
  if (isLoading) return <div>Loading…</div>

  if (isPreview && !isLoading) {
    if (path.match(/\/project\//)) {
      return (
        <ProjectTemplate
          data={{ prismicProject: get(previewData, "prismicProject") }}
        />
      )
    } else {
      return (
        <PageTemplate data={{ prismicPage: get(previewData, "prismicPage") }} />
      )
    }
  }

  return null
}
